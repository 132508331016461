<p-calendar [(ngModel)]="dateModel"
            [dateFormat]="dateFormat"
            [defaultDate]="defaultDateHighlight | dateObject"
            [disabled]="(control | disabledChange | async)!"
            [hideOnDateTimeSelect]="true"
            [maxDate]="maxDate"
            [minDate]="minDate"
            [placeholder]="placeHolder | translate"
            [readonlyInput]="true"
            [showButtonBar]="false"
            [view]="viewMode"
            [firstDayOfWeek]="1"
            [showOtherMonths]="false"
            [iconDisplay]="'input'"
            [showIcon]="true"
            [styleClass]="styleClass"
            [inputStyleClass]="'form__input'"
            [appendTo]="appendTo"
            (onClearClick)="handleSelection()"
            (onSelect)="handleSelection($event)"
></p-calendar>
